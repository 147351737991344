import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";
import { API } from "../../config/urls";
import axios from "axios";
import toast from "react-hot-toast";

const FooterOne = () => {
  const [data, setData] = useState();
  const GetFooter = () => {
    // var axios = require("axios");
    var config = {
      method: "get",
      url: API.getfooter,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        setData(response.data?.[0]?.custom_url);
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetFooter();
  }, []);
const [email,setEmail] = useState("")
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    axios.post(API.postNewsLetter,{email: email}).then((res) => {
      toast.success("Subbscribed successfully")
      setEmail("")
    }).catch(err => toast.error("Failed Sending Email"))
  }
  return (
    <footer>
      <div className="footer-area footer-bg">
        <div className="footer-top">
          <div className="container">
            <div className="footer-logo-area">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="logo">
                    <Link to="/">
                      <img src="/img/logo/logo.png" alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="footer-social-menu">
                    <ul className="list-wrap">
                      {/* {data?.[3]?.custom_url?.map(item => (
                      <li>
                        <a href={item.link}>{item.name}</a>
                    </li>
                    ))} */}
                      <li>
                        <a href="/">Facebook</a>
                      </li>
                      <li>
                        <a href="/">Twitter</a>
                      </li>
                      <li>
                        <a href="/">LinkedIn</a>
                      </li>
                      <li>
                        <a href="/">Instagram</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Contact Us</h4>
                  </div>
                  <div className="footer-content">
                    <p>{data?.[0]?.custom_url?.[0]?.link}</p>
                    {/* <p>Co, FireStone, USA</p> */}
                    <div className="footer-contact">
                      <ul className="list-wrap header-action">
                        <li className="phone-addess">
                          <i className="fas fa-phone-alt"></i>
                          <a href={`tel:${data?.[0]?.custom_url?.[1]?.link}`}>
                            {data?.[0]?.custom_url?.[1]?.link}
                          </a>
                        </li>
                        <li className="email-addess">
                          <a href={`mailto:${data?.[0]?.custom_url?.[2]?.link}`}>
                            {data?.[0]?.custom_url?.[2]?.link}
                          </a>
                        </li>
                        <li className="header-btn mt-4">
                        <Link to="/office-signup?name=Office" className="btn">
                         Office Sign Up
                        </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Our Links</h4>
                  </div>
                  <div className="fw-link-list">
                    <ul className="list-wrap">
                      {data?.[1]?.custom_url?.map((item) => (
                        <li>
                          {/* <Link to={item.link}>{item.name}</Link> */}
                          <p onClick={() => window.location.href = item.link} style={{color: "white", cursor: "pointer"}}>{item.name}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Our Services</h4>
                  </div>
                  <div className="fw-link-list">
                    <ul className="list-wrap">
                      {data?.[2]?.custom_url?.map((item) => (
                        <li>
                          {/* <Link to={item.link}>{item.name}</Link> */}
                          <a href={item.link}>{item.name}</a>
                        </li>
                      ))}
                      {/* <li>
                        <Link to="/services">Accessible Healthcare</Link>
                      </li>
                      <li>
                        <Link to="/services">Virtual Consultations</Link>
                      </li>
                      <li>
                        <Link to="/services">Remote Monitoring</Link>
                      </li>
                      <li>
                        <Link to="/services">Prescription Refills</Link>
                      </li>
                      <li>
                        <Link to="/services">Mental Health Support</Link>
                      </li>
                      <li>
                        <Link to="/services">Specialist Referrals</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Newsletter</h4>
                  </div>
                  <div className="footer-newsletter">
                    <p>
                      Subscribe our newsletter to get our latest update & news
                    </p>
                    <form onSubmit={(data) => handleSubmit(data)} >
                      <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your mail address" />
                      <button type="submit" className="btn">
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer bottom */}
        <FooterBottom />
      </div>
    </footer>
  );
};

export default FooterOne;
