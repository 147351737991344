import React, { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import HeaderNavMenu from "./HeaderNavMenu";
import cn from "classnames";
import { GiHamburgerMenu } from "react-icons/gi";

const HeaderOne = ({ headerClassName, setLoading }) => {
  // menu sticky
  useEffect(() => {
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
        $("#header-fixed-height").removeClass("active-height");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
        $("#header-fixed-height").addClass("active-height");
      }
    });
  }, []);

  return (
    <header className={cn(headerClassName)}>
      {/* <HeaderTop className={topHeaderClassName} /> */}

      {/* <HeaderLogoArea /> */}

      <div id="header-fixed-height" />

      <div id="sticky-header" className="menu-area new-menu">
        <div className="logo ">
          <Link to="/">
            <img
              src="/img/logo/logo.png"
              style={{ maxHeight: "80px", objectFit: "contain" }}
              alt="Logo"
            />
          </Link>
        </div>
        <div className="">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                {/* <i className="fas fa-bars"></i> */}
                <GiHamburgerMenu color="var(--tg-blue)" />
              </div>

              <div className="menu-wrap">
                <nav className="menu-nav">
                  <HeaderNavMenu setLoading={setLoading} />

                  <div className="header-action d-none d-md-block">
                    <ul className="list-wrap">
                      {/* <HeaderSearch /> */}

                      <li>
                        <Link
                          to="https://dashboard.virtualcaremart.com/"
                          target="_blank"
                          className="btn text-white"
                        >
                          Login
                        </Link>
                      </li>
                      <li className="header-btn ">
                        <Link to="/packages" className="btn">
                          Sign Up
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/* 
              <!-- Mobile Menu  --> */}
              <MobileMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOne;
